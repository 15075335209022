import { render, staticRenderFns } from "./PastEvent.vue?vue&type=template&id=48edce86&scoped=true&"
import script from "./PastEvent.vue?vue&type=script&lang=js&"
export * from "./PastEvent.vue?vue&type=script&lang=js&"
import style0 from "./PastEvent.vue?vue&type=style&index=0&id=48edce86&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48edce86",
  null
  
)

export default component.exports