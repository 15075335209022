<template>
  <div>
    <alert-modal confirm ref="confirmation" />

    <EventManagementPage
      class="dashboard"
      title="Event Analytics"
      help-video="https://youtube.com/embed/u1BqzWRPAis"
      @load="onLoad"
      :breadcrumbs="breadcrumbs"
    >
      <product-stat-notice />
      <div class="title-block">
        <h3 id="Event-Title">Event Analytics Breakdown</h3>
        <button id="Reset-Btn" v-on:click="loadCombinedStatistics">
          Reset
        </button>
      </div>
      <div class="chart-container">
        <div class="chart-wrapper">
          <small-chart
            id="clicks"
            title="Event Views"
            :change="changes.clicks"
            :total="totals.clicks"
            v-on:input="
              setChartTimeframe(
                charts.clicks,
                data => data.clicks,
                arguments[0]
              )
            "
          />
          <small-chart
            id="promo-code"
            title="Promo Code Usage"
            :change="changes.promo_codes"
            :total="totals.promo_codes"
            v-on:input="
              setChartTimeframe(
                charts.promo_codes,
                data => data.promo_codes,
                arguments[0]
              )
            "
          />
          <small-chart
            v-for="(tier, index) in tickets[0]"
            :key="tier.name"
            :id="`tickets-0-${index}`"
            :classes="['ticket-graph']"
            :title="`Ticket Sales (${tier.name.trim()})`"
            :change="changes.tickets[0][index]"
            :total="totals.tickets[0][index]"
            v-on:input="
              setChartTimeframe(
                charts.tickets[0][index],
                data => data.tickets[0][index],
                arguments[0]
              )
            "
          />
        </div>
        <div class="chart-wrapper">
          <div class="chart-medium">
            <div class="chart-header">
              <p>Statistics</p>
              <div>
                <chart-timeframe-selector
                  :options="timeframeOptions"
                  v-on:input="
                    setChartTimeframeStatistics(charts.statistics, arguments[0])
                  "
                />
              </div>
            </div>
            <div class="chart-chart-full">
              <canvas id="statistics" width="200px" height="165px"></canvas>
            </div>
          </div>
        </div>
        <div class="chart-wrapper">
          <small-chart
            id="tickets-revenue"
            title="Ticket Sales (Revenue)"
            :change="changes.ticketsRevenue"
            :total="totals.ticketsRevenue"
            v-on:input="
              setChartTimeframe(
                charts.ticketsRevenue,
                data => data.ticketsRevenue,
                arguments[0]
              )
            "
          />
          <small-chart
            v-for="(tier, index) in tickets[2]"
            :key="tier.name"
            :id="`tickets-2-${index}`"
            :classes="['ticket-graph']"
            :title="`Ticket Sales (${tier.name.trim()})`"
            :change="changes.tickets[2][index]"
            :total="totals.tickets[2][index]"
            v-on:input="
              setChartTimeframe(
                charts.tickets[2][index],
                data => data.tickets[2][index],
                arguments[0]
              )
            "
          />
          <small-chart
            id="tickets-quantity"
            title="Ticket Sales (Quantity)"
            :change="changes.ticketsQuantity"
            :total="totals.ticketsQuantity"
            v-on:input="
              setChartTimeframe(
                charts.ticketsQuantity,
                data => data.ticketsQuantity,
                arguments[0]
              )
            "
          />
          <small-chart
            v-for="(tier, index) in tickets[1]"
            :key="tier.name"
            :id="`tickets-1-${index}`"
            :classes="['ticket-graph']"
            :title="`Ticket Sales (${tier.name.trim()})`"
            :change="changes.tickets[1][index]"
            :total="totals.tickets[1][index]"
            v-on:input="
              setChartTimeframe(
                charts.tickets[1][index],
                data => data.tickets[1][index],
                arguments[0]
              )
            "
          />
        </div>
        <div class="chart-wrapper">
          <small-chart
            id="tickets-inperson"
            title="Total ticket In-person sales:"
            :total="totals.inPerson"
            v-on:input="
              setChartTimeframe(
                ticketTypes.inPerson,
                data => data.inPerson,
                arguments[0]
              )
            "
          />
        </div>
        <div class="chart-wrapper">
          <small-chart
            id="tickets-virt"
            title="Total ticket Virtual sales:"
            :total="totals.virtual"
            v-on:input="
              setChartTimeframe(
                ticketTypes.virtual,
                data => data.virtual,
                arguments[0]
              )
            "
          />
        </div>
        <div class="chart-wrapper">
          <small-chart
          id="tickets-donat"
          title="Total ticket Donation sales:"
          :total="totals.donation"
          v-on:input="
              setChartTimeframe(
                ticketTypes.donation,
                data => data.donation,
                arguments[0]
              )
            "
        />
        </div>
        <div class="chart-wrapper">
          <small-chart
            id="clicks"
            title="Total promoters"
            :total="promoterData.total"
            :noChart="true"
          />
        </div>
        <div class="chart-wrapper">
          <small-chart
            id="clicks"
            title="Total promoter sales"
            :total=" '$' + promoterData.sales.toFixed(2)"
            :noChart="true"
          />
        </div>
      </div>

      <div class="title-block">
        <h3 id="Event-Title">Planner App Statistics</h3>
      </div>
      <div class="chart-container">
        <div class="chart-wrapper">
          <small-chart
            id="clicks"
            title="Tickets Scanned In (not unique)"
            :total="appStats.totalScannedTickets"
            :noChart="true"
          />
        </div>
        <div class="chart-wrapper">
          <small-chart
            id="clicks"
            title="Tickets Scanned Out (not unique)"
            :total="appStats.totalScannedOutTickets"
            :noChart="true"
          />
        </div>
        <div class="chart-wrapper">
          <small-chart
            id="clicks"
            title="Return Scans"
            :total="appStats.returnScannedTickets"
            :noChart="true"
          />
        </div>
      </div>

      <div class="chart-container">
        <div class="chart-wrapper">
          <small-chart
            id="clicks"
            title="Tickets Scanned In (unique)"
            :total="appStats.uniqueScannedTickets"
            :noChart="true"
          />
        </div>
        <div class="chart-wrapper">
          <small-chart
            id="clicks"
            title="Tickets Scanned Out (unique)"
            :total="appStats.uniqueScannedOutTickets"
            :noChart="true"
          />
        </div>
        <div class="chart-wrapper">
          <small-chart
            id="clicks"
            title="Total Tickets Not Scanned"
            :total="appStats.totalTicketsNotScanned"
            :noChart="true"
          />
        </div>
      </div>
      <div class="divider"></div>

      <div class="row">
        <div class="col full">
          <table-tote v-model="tableData" />
        </div>
      </div>
      <div class="row">
        <div class="col full">
          <table-tote v-model="ticketData" />
        </div>
      </div>
    </EventManagementPage>
  </div>
</template>

<style lang="less" scoped>
.dashboard {
  #Content {
    .divider {
      margin-top: 30px;
      margin-bottom: 30px;
    }
    // end divider

    .title-block {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;

      #Event-Title {
        display: flex;
      }

      #Reset-Btn {
        display: none;
        justify-content: center;
        margin-left: 15px;
        padding: 3px 0;
        width: 80px;
        height: 25px;
        font-family: inherit;
        font-weight: 700;
        line-height: 1em;
        text-transform: uppercase;
        color: #ffffff;
        border: none;
        border-radius: 15px;
        background-image: linear-gradient(#4eb95e, #419e4f);
        outline: none;
        cursor: pointer;
      }
    }
    // end title-block

    .chart-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;

      .chart-wrapper {
        width: 352px;

        @media screen and (max-width: 1124px) {
          width: 49%;
        }

        @media screen and (max-width: 675px) {
          width: 100%;
        }

        .chart-medium,
        .chart-small {
          border-radius: 15px;
          margin: 0px 0px 21px 0px;
          background-image: var(--dashboard-tiles);
          -webkit-box-shadow: var(--dashboard-shadow);
          -moz-box-shadow: var(--dashboard-shadow);
          box-shadow: var(--dashboard-shadow);
          border: 1px solid var(--dashboard-border);

          .chart-header {
            display: flex;
            align-items: center;
            padding: 0px 20px 0px 20px;
            width: 100%;
            height: 30px;
            justify-content: space-between;
          }
          // end chart-header
        }
        // end chart-medium/chart-small

        .chart-small {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          height: 148.25px;

          .chart-values {
            display: flex;
            flex-wrap: wrap;
            width: 39%;
            height: 120px;

            .chart-totals,
            .chart-direction {
              display: flex;
              justify-content: center;
              width: 100%;
            }
          }

          .chart-chart {
            width: 59%;
            height: 120px;
          }
        }
        // end chart-small

        .chart-medium {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          height: 322px;

          .chart-chart-full {
            width: 100%;
            height: 292px;
          }

          .chart-chart {
            width: 100%;
            height: 212px;
          }

          .chart-values {
            width: 100%;
            height: 80px;

            .chart-direction,
            .chart-totals {
              width: 100%;
              margin: 10px 0px 10px 20px;

              h2,
              p {
                margin: 0px;
              }
            }

            .chart-direction {
              display: flex;

              .icon {
                position: relative;
                top: 0;
                left: 0;
              }

              &.increase {
                color: #4eb95e;
              }

              &.decrease {
                color: #b94e4e;
              }

              p {
                margin-left: 15px;
              }
            }
          }
        }
        // end chart-medium
      }
      // end chart-wrapper
    }
    // end chart-container
  }
  // end Content
}
</style>

<script>
import DiyoboInput from "@/components/DiyoboInput.vue";
import DiyoboButton from "@/components/DiyoboButton.vue";
import PastEvent from "../components/PastEvent.vue";
import LiveEvent from "../components/LiveEvent.vue";
import SmallChart from "@/components/SmallChart.vue";
import ChartTimeframeSelector from "@/components/ChartTimeframeSelector.vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faEllipsisH,
  faArrowUp,
  faArrowDown
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Chart from "chart.js";
import TableTote from "@/components/TableTote.vue";
import dateFormat from "dateformat";
import ProductStatNotice from "../components/ProductStatNotice.vue";
import EventManagementPage from "../components/EventManagementPage.vue";
import dayjs from "dayjs";
import AlertModal from "@/components/modals/AlertModal.vue";

library.add(faEllipsisH, faArrowUp, faArrowDown);

export default {
  name: "event-analytics",
  components: {
    EventManagementPage,
    DiyoboInput,
    DiyoboButton,
    FontAwesomeIcon,
    PastEvent,
    LiveEvent,
    SmallChart,
    ChartTimeframeSelector,
    TableTote,
    ProductStatNotice,
    AlertModal
  },
  head() {
    return {
      title: `${this.title} Analytics`
    };
  },
  data() {
    return {
      breadcrumbs: [
        ["Dashboard", "/dashboard"],
        ["Event Analytics", null]
      ],
      promoterData: {
        total: 0,
        sales: 0,
      },
      chartData: {
        earnings: [],
        statistics: [],
        clicks: [],
        promo_codes: [],
        commission: [],
        tickets: [[], [], []],
        ticketsQuantity: [],
        ticketsRevenue: [],
        visits: [],
        sales: []
      },
      totalData: {
        clicks: [],
        promo_codes: [],
        earnings: [],
        commission: [],
        ticketsQuantity: [],
        ticketsRevenue: [],
        tickets: [
          [[], [], []],
          [[], [], []],
          [[], [], []]
        ]
      },
      changeData: {
        clicks: {},
        promo_codes: {},
        earnings: {},
        commission: {},
        ticketsQuantity: {},
        ticketsRevenue: {},
        tickets: [
          [[], [], []],
          [[], [], []],
          [[], [], []]
        ]
      },
      weeklyChartData: {
        earnings: [],
        clicks: [],
        promo_codes: [],
        commission: [],
        tickets: [[], [], []],
        ticketsQuantity: [],
        ticketsRevenue: []
      },
      data: {},
      title: "",
      tickets: [[], [], []],
      timeframeOptions: [
        { label: "1 Month", value: 1 },
        { label: "6 Months", value: 6 },
        { label: "1 Year", value: 12 }
      ],
      charts: {
        earnings: null,
        statistics: null,
        clicks: null,
        promo_codes: null,
        commission: null,
        ticketsQuantity: null,
        ticketsRevenue: null,
        virtual: null,
        inPerson: null,
        donation: null,
        tickets: [[], [], []]
      },
      changes: {
        clicks: { class: "unchanged", value: "0.00%" },
        promo_codes: { class: "unchanged", value: "0.00%" },
        earnings: { class: "unchanged", value: "0.00%" },
        commission: { class: "unchanged", value: "0.00%" },
        ticketsQuantity: { class: "unchanged", value: "0.00%" },
        ticketsRevenue: { class: "unchanged", value: "0.00%" },
        tickets: [[], [], []]
      },
      totals: {},
      weeks: ["1st - 7th", "8th - 14th", "15th - 21st", "22nd - 28th", "29th+"],
      eventUrl: this.$route.params.url,
      tableData: {},
      ticketData: {},

      tiers: [],
      appStats: {},
      ticketTypes: {
        virtual: {},
        inPerson: {},
        donation: {}
      },
    };
  },
  computed: {
    sessionId() {
      return this.$store.state.session_id;
    },
    months() {
      let months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ];
      return this.shiftArray(months, this.shift);
    },
    indexes() {
      let indexes = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
      return this.shiftArray(indexes, this.shift);
    },
    shift() {
      return 11 - new Date().getMonth();
    }
  },
  methods: {
    setData(name, chartData, totalData) {
      this.chartData[name] = chartData;
      if (totalData != null) {
        this.changeData[name][0] = this.changeData[
          name
        ][1] = this.getChangeData(this.chartData[name]);
        this.totalData[name][0] = totalData(12);
        this.totalData[name][1] = totalData(6);
      }
    },
    setWeeklyData(name, weeklyData, weeklyTotalData) {
      this.weeklyChartData[name] = weeklyData;
      if (weeklyTotalData != null) {
        this.changeData[name][2] = this.getChangeData(
          this.weeklyChartData[name]
        );
        this.totalData[name][2] = weeklyTotalData;
      }
    },
    loadCombinedStatistics() {
      let earnings = this.newEmptyMonthArray();
      let commission = this.newEmptyMonthArray();
      let ticketsQuantity = this.newEmptyMonthArray();
      let ticketsRevenue = this.newEmptyMonthArray();
      let weeklyEarnings = [0, 0, 0, 0, 0];
      let weeklyCommission = [0, 0, 0, 0, 0];
      let weeklyTicketsQuantity = [0, 0, 0, 0, 0];
      let weeklyTicketsRevenue = [0, 0, 0, 0, 0];

      this.tickets.forEach((t, i, a) => (a[i] = []));

      for (let o of this.data.earnings) {
        let date = o.date;
        let earned = new Number(o.earned);
        let index = this.indexes.indexOf(date.getMonth());

        earnings[index] += earned;

        if (date.getMonth() === this.indexes[11])
          this.pushIntoWeeklyArray(date.getDate(), earned, weeklyEarnings);
      }

      for (let c of this.data.commission) {
        let date = c.date;
        let earned = new Number(c.earned);
        let index = this.indexes.indexOf(date.getMonth());

        commission[index] += earned;

        if (date.getMonth() === this.indexes[11])
          this.pushIntoWeeklyArray(date.getDate(), earned, weeklyCommission);
      }

      this.data.tickets.forEach(t => {
        let index = this.indexes.indexOf(t.date.getMonth());

        ticketsQuantity[index] += t.quantity;
        ticketsRevenue[index] += new Number(t.revenue);

        if (t.date.getMonth() === this.indexes[11]) {
          this.pushIntoWeeklyArray(
            t.date.getDate(),
            t.quantity,
            weeklyTicketsQuantity
          );
          this.pushIntoWeeklyArray(
            t.date.getDate(),
            new Number(t.revenue),
            weeklyTicketsRevenue
          );
        }
      });

      this.setData("clicks", this.data.clicks, offset =>
        this.data.clicks.slice(-offset).reduce((a, b) => a + b, 0)
      );
      this.setData("promo_codes", this.data.promo_codes, offset =>
        this.data.promo_codes.slice(-offset).reduce((a, b) => a + b, 0)
      );
      this.setData(
        "earnings",
        earnings,
        offset =>
          "$" +
          earnings
            .slice(-offset)
            .reduce((a, b) => a + b, 0)
            .toFixed(2)
      );
      this.setData("ticketsQuantity", ticketsQuantity, offset =>
        this.data.tickets.map(t => t.quantity).reduce((a, b) => a + b, 0)
      );
      this.setData(
        "ticketsRevenue",
        ticketsRevenue,
        offset => "$" + ticketsRevenue.reduce((a, b) => a + b, 0).toFixed(2)
      );
      this.setData("statistics", [
        this.data.visits[0],
        this.data.promoUsed[0],
        this.data.sales[0]
      ]);
      this.setData("visits", this.data.visits);
      this.setData("sales", this.data.sales);
      this.setData("promoUsed", this.data.promoUsed);

      this.setWeeklyData(
        "clicks",
        this.data.weekly.clicks,
        this.data.weekly.clicks.reduce((a, b) => a + b, 0)
      );
      this.setWeeklyData(
        "promo_codes",
        this.data.weekly.codes,
        this.data.weekly.codes.reduce((a, b) => a + b, 0)
      );
      this.setWeeklyData(
        "earnings",
        weeklyEarnings,
        "$" + weeklyEarnings.reduce((a, b) => a + b, 0).toFixed(2)
      );
      this.setWeeklyData(
        "ticketsQuantity",
        weeklyTicketsQuantity,
        weeklyTicketsQuantity.reduce((a, b) => a + b, 0)
      );
      this.setWeeklyData(
        "ticketsRevenue",
        weeklyTicketsRevenue,
        weeklyTicketsRevenue.reduce((a, b) => a + b, 0)
      );

      let changesObj = {};
      Object.keys(this.changeData).forEach(key => {
        changesObj[key] = this.changeData[key][0];
      });

      let totalsObj = {};
      Object.keys(this.totalData).forEach(key => {
        totalsObj[key] = this.totalData[key][0];
      });
      Object.keys(this.ticketTypes).forEach(key => {
        totalsObj[key] = this.ticketTypes[key][0];
      });
      this.changes = changesObj;
      this.totals = totalsObj;

      // document.getElementById('Event-Title').innerHTML = 'All Events Combined Statistics Overview'
      document.getElementById("Reset-Btn").style.display = "none";

      this.$nextTick(() => this.loadCharts());
    },
    loadEventStatistics(event) {
      let earnings = this.newEmptyMonthArray();
      let commission = this.newEmptyMonthArray();
      let ticketsQuantity = this.newEmptyMonthArray();
      let ticketsRevenue = this.newEmptyMonthArray();
      let weeklyEarnings = [0, 0, 0, 0, 0];
      let weeklyCommission = [0, 0, 0, 0, 0];
      let weeklyTicketsQuantity = [0, 0, 0, 0, 0];
      let weeklyTicketsRevenue = [0, 0, 0, 0, 0];

      this.tickets.forEach((t, i, a) => (a[i] = []));

      let i = 0;
      event.tiers.forEach(t => {
        let index = this.tickets[i].push(t) - 1;
        this.chartData.tickets[i][index] = this.newEmptyMonthArray();
        this.weeklyChartData.tickets[i][index] = [0, 0, 0, 0, 0];

        i++;
        if (i > 2) i = 0;
      });

      for (let o of event.earnings) {
        let date = new Date(+o.date.$date.$numberLong);
        let earned = new Number(o.earned);
        let index = this.indexes.indexOf(date.getMonth());

        earnings[index] += earned;

        if (date.getMonth() === this.indexes[11])
          this.pushIntoWeeklyArray(date.getDate(), earned, weeklyEarnings);
      }

      for (let c of event.commission) {
        let date = new Date(c.date.$date.$numberLong * 1);
        let comm = new Number(c.earned);
        let index = this.indexes.indexOf(date.getMonth());

        commission[index] += comm;

        if (date.getMonth() === this.indexes[11])
          this.pushIntoWeeklyArray(date.getDate(), comm, weeklyCommission);
      }

      for (let o of event.tickets) {
        let date = new Date(o.date.$date.$numberLong * 1);
        let earned = new Number(o.revenue);
        let index = this.indexes.indexOf(date.getMonth());

        ticketsQuantity[index] += o.quantity;
        ticketsRevenue[index] += earned;

        if (date.getMonth() === this.indexes[11]) {
          this.pushIntoWeeklyArray(
            date.getDate(),
            o.quantity,
            weeklyTicketsQuantity
          );
          this.pushIntoWeeklyArray(
            date.getDate(),
            earned,
            weeklyTicketsRevenue
          );
        }

        let column = this.tickets.findIndex(u =>
          u.some(v => v.name === o.tier)
        );

        let row = this.tickets[column].findIndex(u => u.name === o.tier);

        this.chartData.tickets[column][row][index] += earned;
      }

      for (let i = 0; i < this.chartData.tickets.length; i++) {
        for (let j = 0; j < this.chartData.tickets[i].length; j++) {
          this.changeData.tickets[0][i][j] = this.changeData.tickets[1][i][
            j
          ] = this.getChangeData(this.chartData.tickets[i][j]);
          this.totalData.tickets[0][i][j] =
            "$" +
            this.chartData.tickets[i][j].reduce((a, b) => a + b, 0).toFixed(2);
          this.totalData.tickets[1][i][j] =
            "$" +
            this.chartData.tickets[i][j]
              .slice(-6)
              .reduce((a, b) => a + b, 0)
              .toFixed(2);
        }
      }

      this.setData("clicks", event.clicks, offset =>
        event.clicks.slice(-offset).reduce((a, b) => a + b, 0)
      );
      this.setData("promo_codes", event.promo_codes, offset =>
        event.promo_codes.slice(-offset).reduce((a, b) => a + b, 0)
      );
      this.setData(
        "earnings",
        earnings,
        offset =>
          "$" +
          earnings
            .slice(-offset)
            .reduce((a, b) => a + b, 0)
            .toFixed(2)
      );
      this.setData(
        "commission",
        commission,
        offset =>
          "$" +
          commission
            .slice(-offset)
            .reduce((a, b) => a + b, 0)
            .toFixed(2)
      );
      this.setData("ticketsQuantity", ticketsQuantity, offset =>
        event.tickets.map(t => t.quantity).reduce((a, b) => a + b, 0)
      );
      this.setData(
        "ticketsRevenue",
        ticketsRevenue,
        offset =>
          "$" +
          ticketsRevenue
            .slice(-offset)
            .reduce((a, b) => a + b, 0)
            .toFixed(2)
      );
      this.setData("statistics", [
        event.visits[0],
        commission.reduce((a, b) => a + b, 0).toFixed(2),
        event.sales[0]
      ]);
      this.setData("visits", event.visits);
      this.setData("sales", event.sales);

      this.setWeeklyData(
        "clicks",
        event.weekly.clicks,
        event.weekly.clicks.reduce((a, b) => a + b, 0)
      );
      this.setWeeklyData(
        "promo_codes",
        event.weekly.codes,
        event.weekly.codes.reduce((a, b) => a + b, 0)
      );
      this.setWeeklyData(
        "earnings",
        weeklyEarnings,
        "$" + weeklyEarnings.reduce((a, b) => a + b, 0).toFixed(2)
      );
      this.setWeeklyData(
        "commission",
        weeklyCommission,
        "$" + weeklyCommission.reduce((a, b) => a + b, 0).toFixed(2)
      );
      this.setWeeklyData(
        "ticketsQuantity",
        weeklyTicketsQuantity,
        weeklyTicketsQuantity.reduce((a, b) => a + b, 0)
      );
      this.setWeeklyData(
        "ticketsRevenue",
        weeklyTicketsRevenue,
        weeklyTicketsRevenue.reduce((a, b) => a + b, 0).toFixed(2)
      );

      let changesObj = {};
      Object.keys(this.changeData).forEach(key => {
        changesObj[key] = this.changeData[key][0];
      });

      let totalsObj = {};
      Object.keys(this.totalData).forEach(key => {
        totalsObj[key] = this.totalData[key][0];
      });

      this.changes = changesObj;
      this.totals = totalsObj;

      document.getElementById("Event-Title").innerHTML =
        event.name + " Statistics Overview";
      document.getElementById("Reset-Btn").style.display = "flex";
      window.scrollTo({ top: 200, behavior: "smooth" });

      this.$nextTick(() => this.loadCharts());
    },
    loadCharts() {
      if (!this.charts.statistics) {
        this.charts.statistics = new Chart("statistics", {
          data: {
            datasets: [
              {
                data: this.chartData.statistics,
                backgroundColor: [
                  "rgba(7, 19, 184, 0.2)",
                  "rgba(0, 145, 7, 0.2)",
                  "rgba(237, 245, 7, 0.2)"
                ],
                borderColor: [
                  "rgba(7, 19, 184, 1)",
                  "rgba(0, 145, 7, 1)",
                  "rgba(237, 245, 7, 1)"
                ],
                pointHitRadius: 5
              }
            ],
            labels: ["Page Visits", "Promo Usage", "Sales"]
          },
          type: "doughnut",
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              labels: {
                fontColor: "#4eb95e"
              }
            },
            layout: {
              padding: {
                bottom: 10
              }
            },
            tooltips: {
              displayColors: false,
              callbacks: {
                label: (tooltip, data) => {
                  let label = data.labels[tooltip.index];
                  if (label === "Earnings")
                    return `${label}: $${data.datasets[0].data[tooltip.index]}`;
                  return `${label}: ${data.datasets[0].data[tooltip.index]}`;
                }
              }
            }
          }
        });
      } else {
        this.charts.statistics.data.datasets[0].data = this.chartData.statistics;
        this.charts.statistics.update();
      }

      if (!this.charts.clicks) {
        this.charts.clicks = new Chart("clicks", {
          type: "line",
          data: {
            labels: this.months,
            datasets: [
              {
                label: "Views",
                data: this.chartData.clicks,
                borderColor: ["#4eb95e"],
                backgroundColor: ["rgba(0, 0, 0, 0)"],
                pointRadius: 0,
                pointHitRadius: 5
              }
            ]
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: false
            },
            layout: {
              padding: {
                left: 2,
                right: 2,
                top: 3,
                bottom: 5
              }
            },
            scales: {
              xAxes: [
                {
                  ticks: {
                    display: false
                  },
                  gridLines: false
                }
              ],
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    display: false
                  },
                  gridLines: false
                }
              ]
            },
            tooltips: {
              displayColors: false
            }
          }
        });
      } else {
        this.charts.clicks.data.labels = this.months;
        this.charts.clicks.data.datasets[0].data = this.chartData.clicks;
        this.charts.clicks.update();
      }

      if (!this.charts.promo_codes) {
        this.charts.promo_codes = new Chart("promo-code", {
          type: "line",
          data: {
            labels: this.months,
            datasets: [
              {
                label: "Codes Used",
                data: this.chartData.promo_codes,
                borderColor: ["#4eb95e"],
                backgroundColor: ["rgba(0, 0, 0, 0)"],
                pointRadius: 0,
                pointHitRadius: 5
              }
            ]
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: false
            },
            layout: {
              padding: {
                left: 2,
                right: 2,
                top: 3,
                bottom: 5
              }
            },
            scales: {
              xAxes: [
                {
                  ticks: {
                    display: false
                  },
                  gridLines: false
                }
              ],
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    display: false
                  },
                  gridLines: false
                }
              ]
            },
            tooltips: {
              displayColors: false
            }
          }
        });
      } else {
        this.charts.promo_codes.data.labels = this.months;
        this.charts.promo_codes.data.datasets[0].data = this.chartData.promo_codes;
        this.charts.promo_codes.update();
      }

      if (!this.charts.ticketsQuantity) {
        this.charts.ticketsQuantity = new Chart("tickets-quantity", {
          type: "line",
          data: {
            labels: this.months,
            datasets: [
              {
                label: "Tickets Sold",
                data: this.chartData.ticketsQuantity,
                borderColor: ["#4eb95e"],
                backgroundColor: ["rgba(0, 0, 0, 0)"],
                pointRadius: 0,
                pointHitRadius: 5
              }
            ]
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: false
            },
            layout: {
              padding: {
                left: 2,
                right: 2,
                top: 3,
                bottom: 5
              }
            },
            scales: {
              xAxes: [
                {
                  ticks: {
                    display: false
                  },
                  gridLines: false
                }
              ],
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    display: false
                  },
                  gridLines: false
                }
              ]
            },
            tooltips: {
              displayColors: false
            }
          }
        });
      } else {
        this.charts.ticketsQuantity.data.labels = this.months;
        this.charts.ticketsQuantity.data.datasets[0].data = this.chartData.ticketsQuantity;
        this.charts.ticketsQuantity.update();
      }

      if (!this.charts.ticketsRevenue) {
        this.charts.ticketsRevenue = new Chart("tickets-revenue", {
          type: "line",
          data: {
            labels: this.months,
            datasets: [
              {
                label: "Ticket Revenue",
                data: this.chartData.ticketsRevenue,
                borderColor: ["#4eb95e"],
                backgroundColor: ["rgba(0, 0, 0, 0)"],
                pointRadius: 0,
                pointHitRadius: 5
              }
            ]
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: false
            },
            layout: {
              padding: {
                left: 2,
                right: 2,
                top: 3,
                bottom: 5
              }
            },
            scales: {
              xAxes: [
                {
                  ticks: {
                    display: false
                  },
                  gridLines: false
                }
              ],
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    display: false
                  },
                  gridLines: false
                }
              ]
            },
            tooltips: {
              displayColors: false,
              callbacks: {
                label: (tooltip, data) => {
                  return `${
                    data.datasets[tooltip.datasetIndex].label
                  }: $${tooltip.yLabel.toFixed(2)}`;
                }
              }
            }
          }
        });
      } else {
        this.charts.ticketsRevenue.data.labels = this.months;
        this.charts.ticketsRevenue.data.datasets[0].data = this.chartData.ticketsRevenue;
        this.charts.ticketsRevenue.update();
      }

      this.charts.tickets.forEach(a => a.forEach(c => c.destroy()));
      this.charts.tickets = [[], [], []];

      let tickets = document.getElementsByClassName("ticket-graph");
      for (let graph of tickets) {
        let indexes = graph.id
          .split("-")
          .slice(1)
          .map(i => i * 1);
        let chart = new Chart(graph, {
          type: "line",
          data: {
            labels: this.months,
            datasets: [
              {
                label: "Ticket Sales",
                data: this.chartData.tickets[indexes[0]][indexes[1]],
                borderColor: ["#4eb95e"],
                backgroundColor: ["rgba(0, 0, 0, 0)"],
                pointRadius: 0,
                pointHitRadius: 5
              }
            ]
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: false
            },
            layout: {
              padding: {
                left: 2,
                right: 2,
                top: 3,
                bottom: 5
              }
            },
            scales: {
              xAxes: [
                {
                  ticks: {
                    display: false
                  },
                  gridLines: false
                }
              ],
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    display: false
                  },
                  gridLines: false
                }
              ]
            },
            tooltips: {
              displayColors: false,
              callbacks: {
                label: (tooltip, data) => {
                  return `${
                    data.datasets[tooltip.datasetIndex].label
                  }: $${tooltip.yLabel.toFixed(2)}`;
                }
              }
            }
          }
        });

        this.charts.tickets[indexes[0]].push(chart);
      }
    },
    newEmptyMonthArray() {
      return [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    },
    setChartTimeframe(chart, data, offset) {
      const name = (obj, func) => {
        let res = {};
        Object.keys(obj).forEach(key => {
          res[key] = key;
        });
        return func(res);
      };

      if (offset === 1) {
        chart.data.labels = this.weeks;
        chart.data.datasets[0].data = data(this.weeklyChartData);

        this.changes[name(this.changes, data)] = data(this.changeData)[2];
        this.totals[name(this.totals, data)] = data(this.totalData)[2];
      } else {
        chart.data.labels = this.months.slice(-offset);
        chart.data.datasets[0].data = data(this.chartData).slice(-offset);

        if (offset === 6) {
          this.changes[name(this.changes, data)] = data(this.changeData)[1];
          this.totals[name(this.totals, data)] = data(this.totalData)[1];
        } else {
          this.changes[name(this.changes, data)] = data(this.changeData)[0];
          this.totals[name(this.totals, data)] = data(this.totalData)[0];
        }
      }

      chart.update();
    },
    setChartTimeframeStatistics(chart, offset) {
      let visits = this.chartData.visits;
      let earnings = this.chartData.earnings
        .slice(-offset)
        .reduce((a, b) => a + b);
      let sales = this.chartData.sales;
      let data = [];

      if (offset === 1) data = [visits[2], earnings, sales[2]];
      if (offset === 6) data = [visits[1], earnings, sales[1]];
      if (offset === 12) data = [visits[0], earnings, sales[0]];

      chart.data.datasets[0].data = data;
      chart.update();
    },
    getChangeData(data) {
      data = data.slice(-2);
      let increase = data[1] - data[0];
      let result = data[0] !== 0 ? (increase / data[0]) * 100 : 0;
      return {
        class:
          result === 0 ? "unchanged" : result > 0 ? "increase" : "decrease",
        value: `${result === 0 ? "" : result > 0 ? "+" : ""}${result.toFixed(
          2
        )}%`
      };
    },
    shiftArray(array, shift) {
      if (array.length < 2) return array;

      for (let i = 0; i < shift; i++) {
        array.unshift(array.pop());
      }

      return array;
    },
    pushIntoWeeklyArray(date, value, array) {
      if (date <= 7) array[0] += value;
      else if (date <= 14) array[1] += value;
      else if (date <= 21) array[2] += value;
      else if (date <= 28) array[3] += value;
      else if (date > 28) array[4] += value;
    },
    onLoad({ dateID }) {
      // this.$refs.confirmation.open({
      //   title: "Maintenance Underway",
      //   msg: "Click okay to go back to the dashboard.",
      //   onConfirm: () => {
      //     this.active = false;
      //     document.body.style.overflow = "auto";
      //     this.$router.push("/dashboard");
      //   }
      // });
      this.$axios
        .post("/planners/event-analytics", {
          event: this.eventUrl,
          date: dateID
        })
        .then(response => {
          // DISABLED FOR LAUNCH
          let data = response.data;
          this.data = data.stats;
          this.title = data.title;
          this.appStats = data.appStats;
          this.data.clicks = this.shiftArray(this.data.clicks, this.shift);
          this.data.promo_codes = this.shiftArray(this.data.codes, this.shift);
          this.data.tickets.forEach(t => (t.date = new Date(t.date * 1)));
          this.data.earnings.forEach(e => (e.date = new Date(e.date * 1)));
          this.data.commission.forEach(c => (c.date = new Date(c.date * 1)));
          this.promoterData = data.promoters;
          console.log(data.stats);
          this.ticketTypes.virtual = this.shiftArray(data.stats.virtual);
          this.ticketTypes.donation = this.shiftArray(data.stats.donation);
          this.ticketTypes.inPerson = this.shiftArray(data.stats.inPerson);
          this.loadCombinedStatistics();
        });
      this.$axios
        .post("/planners/event-tiers", {
          url: this.eventUrl,
          date: dateID
        })
        .then(response => {
          let data = response.data;

          data.forEach(ele => {
            ele.ticket_date = "";
            ele.ticket_time = "";

            if (ele.entryDate && ele.entryDate.$date) {
              ele.ticket_date = dayjs(+ele.entryDate.$date.$numberLong).format(
                "MMM DD, YYYY"
              );

              if (ele.type === "Date and Time") {
                ele.ticket_time = dayjs(
                  +ele.entryDate.$date.$numberLong
                ).format("h:mm A");
              }
            }
            ele.ticketsAvailable =
              ele.ticketsAvailable < 0 ? 0 : ele.ticketsAvailable;
          });

          const hasNoTierDates = data.every(t => !t.ticket_date);
          const hasNoTierTimes = data.every(t => !t.ticket_time);

          this.tableData = {
            title: "Tier Breakdown",
            columns: [
              {
                title: "Tier Name",
                field: "name",
                width: hasNoTierDates && hasNoTierTimes ? "48%" : "27%"
              },
              {
                title: "Tier Time",
                field: "ticket_time",
                width: "10%",
                hidden: hasNoTierTimes,
                date: true
              },
              { title: "Tickets Sold", field: "ticketsSold", width: "12%" },
              {
                title: "Tickets Printed",
                field: "ticketsPrinted",
                width: "14%"
              },
              { title: "Ticket Refunds", field: "ticketRefunds", width: "14%" },
              {
                title: "Tickets Comp/Willcall",
                field: "compsWillcall",
                width: "18%"
              },
              {
                title: "Tickets Available",
                field: "ticketsAvailable",
                width: "14%"
              }
            ],
            data: data,
            searchable: ["name"],
            callbacks: {
              active: this.updatePromoCode
            }
          };
        });

      this.$axios
        .post("/planners/unscanned-tickets", {
          url: this.eventUrl,
          date: dateID
        })
        .then(response => {
          let data = response.data.list;

          this.ticketData = {
            title: "Tickets Not Scanned",
            columns: [
              { title: "Order No.", field: "validation", width: "18%" },
              { title: "# Number", field: "number", width: "10%" },

              { title: "Name", field: "name", width: "15%" },
              { title: "Email", field: "email", width: "30%" },
              { title: "Phone", field: "phone", width: "18%" },
              { title: "Tier", field: "tier", width: "18%" },
              {
                title: "Notes",
                field: "notes",
                width: "21%",
                date: true
              }
            ],
            data: data,
            searchable: ["name"],
            callbacks: {
              // active: this.updatePromoCode,
            }
          };
        });
    }
  }
};
</script>
