<template>
  <div class="past-event">
    <div class="event-inner">
      <div class="left">
        <div class="event-details">
          <a class="event-name" :href="fullURL" target="_blank">{{ name }}</a>
          <div v-if="event.owner" class="added-by">
            Added by {{ event.owner }}
          </div>
          <div class="event-date">{{ eventDate }} - {{ eventEndDate }}</div>
          <div class="creation-date">Created on {{ creationDate }}</div>
          <div class="event-location">{{ eventLocation }}</div>
        </div>
      </div>

      <div class="right">
        <button v-on:click="redirect('view')">View Event</button>

        <div class="event-options">
          <span>Select Event Options</span>
          <!-- <span> Select Event Options</span> -->
          <event-options
            class="past-events-options"
            id="event-menu"
            :options="options"
            :eventUrl="event.url"
            :activated="event.userActivated"
            :cancelled="event.cancelled"
            :deactivateEligible="event.deactivateEligible"
            v-on:cancel="onCancel"
            v-on:redirect="redirect"
          />
          <!-- <diyobo-input
          type="dropdown-no-label"
          placeholder="Select Event Options"
          linksOnly
          :options="options"
          :redirect="true"
          v-on:redirect="redirect"
        /> -->
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.event-location {
  margin-top: 8px;
}
.past-event {
  margin: 0.75em auto;
  .event-inner {
    border-radius: 8px;
    box-shadow: 1px 1px 2px -1px #ffffff36, inset 0px 1px 5px 0px #00000069;
    // background-image: linear-gradient(#1f1f1f, #161616);
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 52px;

    .left {
      // width: calc(100% - 400px);
      height: inherit;
      margin: 20px 0;
      max-width: 60%;

      .event-details {
        padding: 0 20px;
        position: relative;
        // width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .event-name {
          color: var(--text);
          font-size: 1.75em;
          font-weight: 700;
          word-break: break-word;
          line-height: 32px;
        }

        .event-date {
          margin: 10px 0;
          font-weight: 600;
          text-transform: uppercase;

          font-size: 1.05em;
          line-height: 1em;
        }
        .creation-date {
          position: relative;
          // bottom: -48px;
          color: var(--headers);
          font-size: 1.05em;
          font-weight: 600;
          text-transform: uppercase;
          line-height: 1em;
        }
        .added-by {
          margin: 8px 0 0 0;
        }
      }
    }
    // end left
    .right {
      display: flex;
      flex-wrap: wrap;
      flex-flow: column;
      justify-content: space-evenly;
      width: 380px;
      margin: 10px;
      margin-left: 20px;

      .tix-sold {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 250px;
        padding: 5px 1px;
        // height: 64px;
        margin: 10px 0;
        margin-bottom: 0;

        border: 1px solid #333b3f;
        /* border-top: none; */
        border-radius: 10px;
        background-color: #1c1f22;
        box-shadow: 0px 0px 20px 0px #00000057;
        .tix-sold-number {
          margin-left: 10px;
          font-size: 3em;
          font-weight: 700;
          color: #4a639e;
        }
        .tix-sold-label {
          padding: 5px 8px;
          // width: 75px;
          font-size: 15px;
          font-weight: 700;
          line-height: 100%;
          text-transform: uppercase;
        }
      }
      button {
        // margin: 10px;
        margin-bottom: 10px;
        padding: 3px 0;
        width: 100%;
        min-height: 50px;
        height: 52px;
        font-family: inherit;
        font-size: 1.25em;
        font-weight: 700;
        line-height: 1em;
        text-transform: uppercase;
        color: #ffffff;
        border: none;
        border-radius: 10px;
        background-color: var(--secondary-green);
        -webkit-appearance: none;
        outline: none;
        cursor: pointer;
      }
      // end button
      &::v-deep .event-options {
        // color: #828282;
        /* padding-top: 3px; */
        background-image: var(--dropdown-background);
        // border: 1px solid var(--dropdown-border);
        box-shadow: var(--dashboard-shadow);
        width: 100%;
        align-items: center;
        border-radius: 10px;
        height: 50px;
        position: relative;
        display: flex;
        // margin: 10px 10px 10px 0;

        span {
          width: 100%;
          height: fit-content;
          /* text-align: center; */
          /* line-height: 0px; */
          right: 50%;
          font-size: 17px;
          margin: 0 20px;
          // font-weight: bold;
        }

        .past-events-options {
          position: absolute;
          width: 100%;
          height: 100%;
          bottom: initial;
          /* bottom: 35%; */
          pointer-events: none;

          .menu {
            // top: 20px;
            top: initial;
            height: 100%;
            position: relative;
            right: initial;
            width: 100%;

            &.active {
              transform: rotate(0deg);

              svg {
                transform: rotate(180deg);
              }
            }

            svg {
              transition: 0.5s;
              position: absolute;
              right: 15px;
              top: 14px;
            }
          }

          .options {
            .options-inner {
              width: 100%;
              top: 11px;
              right: 0px;
            }
          }
        }
        // margin-right: 10px;
        // // margin-bottom: -1em;
        // padding-top: 3px;
        // border: 1px solid white;
        // width: 350px;
        // height: 60px;

        .input-wrapper {
          margin-bottom: 1.75em;
        }
      }
      // end event-options
    }
    // end right
  }
  @media screen and (max-width: 780px) {
    .event-inner {
      max-width: min-content;

      .right {
        margin-bottom: 20px;

        .tix-sold {
          width: 261px;
        }
        button {
          margin-right: 0;
        }
        &::v-deep .event-options {
          margin-right: 0;
        }
        margin-right: 20px;
        // margin-bottom: 10px;
      }
    }
  }
  // border: 2px solid #424242;
  // border-radius: 8px;
  // background-image: linear-gradient(#1f1f1f, #161616);

  // .event-inner {
  //   display: flex;

  //   .event-details {
  //     padding: 8px 18px;
  //     width: calc(100% - 460px);
  //     min-height: 52px;

  //     .event-name {
  //       font-size: 1.15em;
  //       font-weight: 700;
  //       line-height: 1em;
  //     }

  //     .event-date {
  //       margin: 0.15em auto;
  //       margin-top: 0.05em;
  //       line-height: 1em;
  //     }

  //     .creation-date {
  //       color: #4a639e;
  //       font-size: 0.8em;
  //       font-weight: 700;
  //       text-transform: uppercase;
  //       line-height: 1em;
  //     }
  //   }

  //   .event-options {
  //     margin-right: 10px;
  //     // margin-bottom: -1.5em;
  //     // padding-top: 3px;
  //     width: 350px;
  //     position: relative;

  //     .past-events-options {
  //       bottom: initial;

  //       .menu {
  //         top: 23px;
  //       }

  //       .options {
  //         .options-inner {
  //           width: 110%;
  //           top: 10px;
  //           right: 0px;
  //         }
  //       }
  //     }
  //   }

  //   button {
  //     margin: 0;
  //     width: 100px;
  //     font-family: inherit;
  //     font-size: 1em;
  //     font-weight: 700;
  //     line-height: 1em;
  //     text-transform: uppercase;
  //     color: #ffffff;
  //     border: none;
  //     border-radius: 0 6px 6px 0;
  //     background-image: linear-gradient(#4d65a5, #404b78);
  //     -webkit-appearance: none;
  //     outline: none;
  //     cursor: pointer;
  //   }
  // }
}
@media screen and (max-width: 456px) {
  .live-event {
    .event-inner {
      .right {
        margin-bottom: 20px;
        .tix-sold {
          width: 100%;
        }
        button {
          margin-left: 0;
          width: 100%;
        }
      }
      .event-details {
        .event-name {
          font-size: 1em;
        }
        .event-date {
          font-size: 0.9em;
        }
        .creation-date {
          font-size: 0.7em;
        }
      }
    }
  }
}
// @media screen and (max-width: 375px) {
//   .past-event {
//     .event-inner {
//       .event-details {
//         .event-name {
//           font-size: 1em;
//         }

//         .event-date {
//           font-size: 0.9em;
//         }

//         .creation-date {
//           font-size: 0.7em;
//         }
//       }
//     }
//   }
// }
</style>

<script>
import DiyoboInput from "@/components/DiyoboInput.vue";
import dateFormat from "dateformat";
import EventOptions from "@/components/EventOptions.vue";
import { whiteLabelRedirection } from "@/helpers/whiteLabel";

import dayjs from "dayjs";

var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advanced);
function sort(items) {
  const sorted = [...items].sort((a, b) => a.label.localeCompare(b.label));
  const list = new Array(sorted.length);

  for (let i = 0; i < sorted.length; i++) {
    const index =
      i % 2 === 0
        ? Math.floor(i / 2)
        : Math.floor(i / 2) + Math.ceil(sorted.length / 2);

    list[i] = sorted[index];
  }

  if (sorted.length % 2 === 1) {
    list[sorted.length] = {
      dummy: true
    };
  }

  return list;
}

export default {
  name: "past-event",
  components: {
    DiyoboInput,
    EventOptions
  },
  props: {
    url: String,
    name: String,
    date: Date,
    created: Date,
    event: Object
  },
  computed: {
    eventDate() {
      return dayjs(+this.event.startInt)
        .tz(this.event.timezone)
        .format("ddd, MMM DD, YYYY h:mm A z");
      return dateFormat(
        +this.event.startInt,
        "ddd, mmm dS, yyyy",
        this.event.timezone
      );
    },
    eventEndDate() {
      return dayjs(+this.event.end.$date.$numberLong)
        .tz(this.event.timezone)
        .format("ddd, MMM DD, YYYY h:mm A z");
      return dateFormat(
        +this.event.end.$date.$numberLong,
        "ddd, mmm dS, yyyy",
        this.event.timezone
      );
    },
    creationDate() {
      return dateFormat(this.created, "ddd, mmm dS, yyyy");
    },
    eventLocation() {
      if (this.event.eventType != "VIRTUAL") {
        return `${this.event.city}, ${this.event.region_name}`;
      } else {
        return "Online Event";
      }
    },
    fullURL() {
      switch (process.env.VUE_APP_ENVIRONMENT) {
        case "development":
          return "http://localhost:3100/" + this.url;
        case "staging":
          return "https://staging.incredevent.com/" + this.url;
        default:
          return "https://incredevent.com/" + this.url;
      }
    }
  },
  data() {
    return {
      options: [
        ...sort([
          {
            label: "Analytics",
            value: "1",
            icon: "icon-chart.png",
            type: "1",
            link: "/event-analytics",
            inactive: false,
            permission: "ANALYTICS"
          },
          {
            label: "Financials",
            value: "financials",
            icon: "icon-money.png",
            type: "external",
            link: "/financials",
            inactive: false,
            permission: "FINANCIALS"
          },
          {
            label: "Automation",
            value: "2",
            icon: "icon-automation.png",
            type: "1",
            link: "#",
            inactive: true
          },
          {
            label: "Email",
            value: "6",
            icon: "icon-email.png",
            type: "1",
            link: "/instant-email",
            inactive: false,
            permission: "EMAIL_MANAGEMENT"
          },
          {
            label: "Clone",
            value: "4",
            icon: "icon-copy.png",
            type: "external",
            link: "#",
            inactive: this.event.tiers.some(t => t.type !== "General Tier"),
            permission: "CREATE_EVENT"
          },
          {
            label: "Print",
            value: "11",
            icon: "icon-print.png",
            type: "1",
            link: "/print",
            inactive: false,
            permission: "PRINT_TICKETS"
          },
          {
            label: "Comps/Will Call",
            value: "5",
            icon: "icon-willcall.png",
            type: "1",
            link: "/comps",
            inactive: true,
            permission: "COMPS_WILLCALL"
          },
          {
            label: "Discounts",
            value: "13",
            icon: "icon-percentage.png",
            type: "1",
            link: "/discounts",
            inactive: false,
            permission: "PROMO_CODES"
          },
          {
            label: "Edit",
            value: "7",
            icon: "icon-edit.png",
            type: "1",
            link: `/edit-event`,
            inactive: true,
            permission: "EDIT_EVENT"
          },
          {
            label: "Promoter Dashboard",
            value: "12",
            icon: "icon-users.png",
            type: "1",
            link: "/promoters",
            inactive: false,
            permission: "PROMOTER_DASHBOARD"
          },
          {
            label: "Embed",
            value: "9",
            icon: "icon-code.png",
            type: "1",
            link: "/embeds",
            inactive: true,
            permission: "EMBED_MANAGEMENT"
          },
          {
            label: "Guest List",
            value: "14",
            icon: "icon-ticket.png",
            type: "1",
            link: "/guestlist",
            inactive: true,
            permission: "GUEST_LIST"
          },
          {
            label: "Event Buyers List",
            value: "3",
            icon: "icon-ticketlist.png",
            type: "1",
            link: "/event-tickets-list",
            inactive: false,
            permission: "EVENT_BUYERS_LIST"
          },
          {
            label: "Custom Fields",
            value: "17",
            icon: "icon-cms.png",
            type: "1",
            link: "/fields",
            inactive: false,
            permission: "EDIT_EVENT"
          },
          {
            label: "User Permissions",
            value: "15",
            icon: "icon-user.png",
            type: "1",
            link: "#",
            inactive: true
          }
        ])
      ]
    };
  },
  methods: {
    async redirect(val) {
      switch (val) {
        case "financials":
          this.$router.push(
            "/financials/" +
              this.$store.state.activeBrand.subdomain +
              "/" +
              this.url
          );
          break;
        case "edit":
          this.$router.push("/edit-event/" + this.url);
          break;
        case "4":
          this.$emit("openCloneModalPopUp", this.event);
          break;
        case "view":
          const domainUrl = await whiteLabelRedirection(
            process.env.VUE_APP_ENVIRONMENT,
            this.event.user,
            this.url
          );

          window.open(`${domainUrl}`, "_blank");
          break;
        case "promo":
          this.$router.push("/discounts/" + this.url);
          break;
        case "emails":
          this.$router.push("/instant-email/" + this.url);
          break;
        case "promoters":
          this.$router.push("/promoter-dashboard/" + this.url);
          break;
        case "embed":
          this.$router.push("/embeds/" + this.url);
          break;
        case "comps":
          this.$router.push("/comps/" + this.url);
          break;
        case "analytics":
          this.$router.push("/event-analytics/" + this.url);
          break;
        case "print":
          this.$router.push("/print/" + this.url);
          break;
        case "surveys":
          this.$router.push("/automated-surveys");
          break;
        case "buyers":
          this.$router.push("/event-tickets-list/" + this.url);
          break;
        default:
          break;
      }
    },
    onCancel() {}
  },
  created() {
    // user permissions
    try {
      if (this.event.permissions) {
        for (const option of this.options) {
          const permission = this.event.permissions[option.permission];

          if (permission !== undefined && !option.inactive) {
            option.inactive = !permission;
          } else {
            option.inactive = true;
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  }
};
</script>
