import axios from "axios";

export const whiteLabelRedirection = async (appEnvironment, userId, url) => {
  let stringBuilder = "";
  const { data } = await axios.post("/white-label/get-white-label-by-user-id", {
    userId: userId,
  });
  if (data.hasDomain) {
    if (
      data.whiteLabel.domainName &&
      !data.whiteLabel.domainName.startsWith("http")
    ) {
      data.whiteLabel.domainName =
        "https://" + data.whiteLabel.domainName + "/";
    }

    if (
      data.whiteLabel.stagingDomainName &&
      !data.whiteLabel.stagingDomainName.startsWith("http")
    ) {
      data.whiteLabel.stagingDomainName =
        "https://" + data.whiteLabel.stagingDomainName + "/";
    }
  }

  switch (appEnvironment) {
    case "development":
      stringBuilder = data.hasDomain
        ? data.whiteLabel.domainName
        : "http://localhost:3100/";
      break;
    case "staging":
      stringBuilder = data.hasDomain
        ? data.whiteLabel.stagingDomainName
        : "http://staging.incredevent.com/";
      break;
    default:
      stringBuilder = data.hasDomain
        ? data.whiteLabel.domainName
        : "https://incredevent.com/";
      break;
  }
  stringBuilder += url;

  if (data.hasDomain) {
    const resp = await axios.post("/white-label/create-redirects", {
      userId: userId,
      link: stringBuilder,
      url,
    });

    if (resp.data.$oid) {
      stringBuilder += "?redirects=" + resp.data.$oid;
    }
  }

  return stringBuilder;
};
